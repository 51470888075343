import { Title } from '@solidjs/meta';
import { LinkButton, Link, TextLink } from '@troon/ui';
import { StyleCard } from '../_style-card';

export default function LinksPage() {
	return (
		<>
			<Title>Links | Interactive | Style Guide | Troon</Title>
			<h1 class="text-3xl font-semibold">Links</h1>

			<div class="mb-8 grid grid-cols-12 gap-4">
				<StyleCard title="Link">
					<Link href="#">Unstyled link</Link>
					<TextLink href="#">Text link</TextLink>
				</StyleCard>
				<StyleCard title="Buttons">
					<LinkButton href="#">Primary</LinkButton>
					<LinkButton appearance="current" href="#">
						Current
					</LinkButton>
					<LinkButton href="#" size="sm">
						Primary small
					</LinkButton>
					<LinkButton appearance="current" size="sm" href="#">
						Current small
					</LinkButton>
				</StyleCard>
			</div>
		</>
	);
}
